import { h, FunctionalComponent } from 'preact';
import * as style from './tableheader.scss';

interface TableHeaderProps {
  onHeaderClick(colName: string): void;
}

export const TableHeader: FunctionalComponent<TableHeaderProps> = ({ onHeaderClick }) => {
  const handleColumnClick = (colName: string) => {
    onHeaderClick(colName);
  };
  return (
    <div class={style.tableHeaderDiv}>
      <div class={`${style.id} ${style.hoverPointer}`} onClick={() => handleColumnClick('id')}>
        ID
      </div>
      <div class={`${style.date} ${style.hoverPointer}`} onClick={() => handleColumnClick('date')}>
        Date
      </div>
      <div class={`${style.tiny} ${style.hoverPointer}`} onClick={() => handleColumnClick('short')}>
        TinyURL <span class={style.topLabel}>(Clk to GO)</span>
      </div>
      <div class={`${style.desc} ${style.hoverPointer}`} onClick={() => handleColumnClick('description')}>
        Description <span class={style.topLabel}>(Click to EDIT)</span>
      </div>
      <div class={`${style.cnt} ${style.hoverPointer}`}>Cnt</div>
      <div class={`${style.del} ${style.hoverPointer}`}>Del</div>
      <div class={`${style.cpO} ${style.hoverPointer}`}>CpO</div>
      <div class={`${style.cpS} ${style.hoverPointer}`}>CpS</div>
    </div>
  );
};
