import { h, FunctionalComponent, JSX } from 'preact'; /* , Fragment */
import * as style from './chatBox.scss';
import { useState } from 'preact/compat'; /* useEffect */
import { IconPeople } from '../GitHub Icons/IconPeople';

interface ChatBoxProps {
  onCloseXClick(): void;
}

export const ChatBox: FunctionalComponent<ChatBoxProps> = ({ onCloseXClick }) => {
  const [cbOutMsg, setCbOutMsg] = useState('');
  const handleCloseXClick = () => {
    onCloseXClick();
  };
  const handleOutMsgKeyup = (e: JSX.TargetedEvent<HTMLInputElement, Event>) => {
    const val = e.currentTarget.value;
    setCbOutMsg(val);
  };
  return (
    <div class={style.chatBoxDiv}>
      <div class={style.cbHeader}>
        <div class={style.cbHeaderSpacer} />
        <div class={style.cbTitle}>Chat Box</div>
        <div class={style.cbCloseX}>
          <span onClick={handleCloseXClick}> X </span>
        </div>
      </div>
      <div class={style.cbMainPanel}>
        <div class={style.cbSidebar}>
          <h4 class={style.cbSbHeader}>
            <IconPeople size={2} color={'dodgerblue'} />
            <div style={'margin-left: 11px;'}>Users</div>
          </h4>
          <div class={style.cbUsersOnlineDiv}>
            <div class={style.cbUsersTopSpacer}></div>
          </div>
          <div class={style.cbRecipientDiv}>
            <input disabled class={`${style.formControl} ${style.cbRecipInput}`} placeholder={'Click a User'} />
          </div>
        </div>
        <div class={style.cbMessages}>
          <div class={style.cbHistory}></div>
          <div class={style.cbCompose}>
            <input
              onKeyUp={handleOutMsgKeyup}
              value={cbOutMsg}
              class={`${style.formControl} ${style.cbRecipInput}`}
              placeholder={'Type new message, press Enter to send'}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
