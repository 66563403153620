import { h, Component, Fragment, createRef } from 'preact';
import * as style from './app.scss';
import { copyToClipboard } from '../utils/copyToClipboard';
import { debounce } from '../utils/debounce';
import { TableRow } from './Components/TableRow';
import { TableHeader } from './Components/TableHeader';
import { get, post } from '../utils/ajax';
import { ChatBox } from './Components/Chat/ChatBox';

export interface AllExisting {
  id: number;
  date: string;
  short: string;
  original: string;
  description: string;
  acc_cnt: string | number;
}
interface ReturnedShortURL {
  host: string;
  shortUrl: string;
}
interface AppState {
  original?: string;
  shortened?: string;
  description?: string;
  //allContent: undefined | string;
  error?: string;
  showAllExisting?: boolean;
  result?: ReturnedShortURL;
  allExisting?: AllExisting[];
  rowToEdit?: AllExisting;
  showChatBox?: boolean;
  showDataInput?: boolean;
  inputData?: string;
  sortDirection?: 'asc' | 'desc';
  sortColumn?: keyof AllExisting;
}
export class App extends Component<{}, AppState> {
  dataInputRef = createRef();
  origUrlInputRef = createRef();
  public constructor(props: {}) {
    super(props);
    this.state = { sortDirection: 'asc', showChatBox: false };
  }
  /* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
  public render(
    {},
    {
      original,
      description,
      shortened,
      error,
      result,
      allExisting,
      showAllExisting,
      rowToEdit,
      showChatBox,
      showDataInput,
      inputData,
      sortColumn,
      sortDirection,
    }: AppState
  ) {
    const urlInputBG = result !== undefined && error === undefined ? style.bgSuccess : '';
    const aliasInputBG = result !== undefined && error === undefined ? style.bgSuccess : '';
    const columnSorter = (sortColumn: keyof AllExisting | undefined, sortDirection: 'asc' | 'desc') => (a: AllExisting, b: AllExisting) => {
      if (sortColumn === undefined) return 0;
      const first = sortDirection === 'asc' ? a[sortColumn] : b[sortColumn];
      const second = sortDirection === 'asc' ? b[sortColumn] : a[sortColumn];
      if (typeof first === 'string' && typeof second === 'string') {
        return first?.localeCompare(second);
      } else if (a[sortColumn] !== undefined) {
        return +a[sortColumn] - +b[sortColumn];
      }
      return 0;
    };

    return (
      <Fragment>
        {showAllExisting === true && (
          <div class={style.allExistingOlayDiv}>
            <div class={style.allExistingTable}>
              <div class={style.tableCloseX}>
                <span
                  onClick={() => {
                    this.setState({ showAllExisting: !this.state.showAllExisting });
                  }}
                >
                  X
                </span>
              </div>
              <TableHeader onHeaderClick={this.handleHeaderClick} />
              <div class={style.allTableRows}>
                {allExisting !== undefined &&
                  allExisting.length > 0 &&
                  allExisting
                    .sort(columnSorter(sortColumn, sortDirection ?? 'asc'))
                    .map((r) => (
                      <TableRow
                        row={r}
                        key={r.id}
                        onDel={this.handleDelete}
                        onEdit={this.handleEdit}
                        onCopyShort={this.handleCopyShort}
                        onCopyOriginal={this.handleCopyOriginal}
                      />
                    ))}
              </div>
            </div>
          </div>
        )}
        <div class={style.nav}>
          <div class={style.navBtn}>
            <a href='https://url.hcqwerx.com'>url</a>
          </div>
          <div class={style.navBtn}>
            <a href='https://newz.icu'>newz</a>
          </div>
          <div class={style.navBtn}>
            <a href='https://forum.hcqwerx.com'>forum</a>
          </div>
          <div class={style.navBtn}>
            <a href='https://video.hcqwerx.com'>video</a>
          </div>
        </div>
        <div class={style.mainAppWrapper}>
          <h2>
            <a href='https://hcqwerx.com' target='_blank' rel='noopener noreferrer'>
              hcqWerx&trade;
            </a>
          </h2>
          <h1>URL Shortener / Forwarder:</h1>
          <div class={style.appIntroBlurb}>
            Similar to{' '}
            <a href='https://tinyurl.com' target='_blank' rel='noopener noreferrer'>
              TinyURL
            </a>{' '}
            or{' '}
            <a href='https://bit.ly' target='_blank' rel='noopener noreferrer'>
              bit.ly
            </a>{' '}
            or{' '}
            <a href='https://goo.gl' target='_blank' rel='noopener noreferrer'>
              goo.gl
            </a>
            , this utility reduces a loooooong web address into something both more manageable and more memorable. Use the shortened URL instead of the long one in
            emails, blogs, twitter or facebook posts, etc &ndash; whether as a mnemonic or simply for convenience. Anyone visiting the shortened{' '}
            <span onClick={this.handleDataUploadRequest}>U</span>RL will be instantly redirected to the original, long URL.
          </div>
          <div class={`${style.tinyUrlDialogForm} ${style.formPlacement}`}>
            <div class={`${style.originalUrlLabel} ${style.lblDiv}`}>
              <span class={style.lblSpan}>URL to shorten:</span>
            </div>
            <div class={style.originalInput}>
              <input
                class={`${style.formControl} ${style.jdInput} ${urlInputBG}`}
                type='text'
                ref={this.origUrlInputRef}
                placeholder='https://domain.com?Paste-your-looong-web-address-in-here&pick-your-own-easy-to-remember-short-url-if-desired'
                value={original}
                onKeyUp={this.checkFullyQualified}
              />
            </div>
            <div class={`${style.descriptionLabel} ${style.lblDiv}`}>
              <span class={style.lblSpan}>Description:</span>
            </div>
            <div class={style.descriptionInput}>
              <input
                class={`${style.formControl} ${style.jdInput} ${urlInputBG}`}
                type='text'
                style={'width:100%;'}
                placeholder='Enter a brief description (especially if a YouTube video, etc)'
                value={description}
                onChange={(e: any) => this.handleDescriptionInput(e)}
              />
            </div>
            <div class={`${style.shortNameLabel} ${style.lblDiv}`}>
              <span class={style.lblSpan}>Short URL (optional): </span>
              <input
                class={`${style.formControl} ${style.jdInput} ${aliasInputBG}`}
                type='text'
                placeholder='desired-short-url (OPTIONAL)'
                value={shortened}
                style={'min-width:222px;'}
                onKeyUp={this.checkShortUrlInput}
              />
            </div>
            <div class={style.submitWrapper}>
              <button onClick={this.handleSubmit} disabled={error !== undefined || original === undefined}>
                Submit
              </button>
            </div>
            {error && <div class={style.errorMessage}>{error}</div>}
            {result !== undefined && (
              <div class={style.successWrap}>
                <div class={style.successMessage}>
                  <div>URL minificized.</div>
                  <div>
                    The new short URL is: <a href={`${result.host}/${result.shortUrl}`}>{`${result.host}/${result.shortUrl}`}</a>
                  </div>
                  <div>The shortened URL has been placed on your clipboard for immediate pasteability.</div>
                </div>
                <div class={style.btnResetDiv}>
                  <button onClick={() => this.handleBtnRecopyToClipboard(result)}>Recopy to Clipboard</button>
                  <button onClick={this.handleBtnReset}>Reset</button>
                </div>
              </div>
            )}
          </div>
          <div class={style.chatOpenTriggerDiv} onClick={() => this.setState({ showChatBox: true })}></div>
        </div>
        <footer class={style.versionTime}>0.112071722</footer>
        {rowToEdit !== undefined && (
          <div class={`${style.editEntryOlayDiv} ${style.editOlay}`}>
            <div class={style.editForm}>
              <div class={style.tableCloseX}>
                <span
                  onClick={() => {
                    this.setState({ rowToEdit: undefined });
                  }}
                >
                  {' X '}
                </span>
              </div>
              <div class={style.tinyurlDiv}>
                {'Tiny URL: '}
                <input onChange={(e: any) => this.updateTinyUrl(e)} value={rowToEdit.short} />
              </div>
              <div class={style.urlDescDiv}>
                {'Description: '}
                <textarea rows={5} cols={80} onChange={(e: any) => this.updateDescription(e)} value={rowToEdit.description}></textarea>
              </div>
              <div class={style.btnSaveDiv}>
                <button class={style.btnSave} onClick={this.handleEditSave}>
                  {'Save '}
                </button>
              </div>
            </div>
          </div>
        )}
        {showDataInput !== undefined && showDataInput && (
          <div class={`${style.showDataInputOlayDiv} ${style.editOlay}`}>
            <div class={style.editForm}>
              <div class={style.tableCloseX}>
                <span
                  onClick={() => {
                    this.setState({ showDataInput: undefined });
                  }}
                >
                  {' X '}
                </span>
              </div>
              <div class={style.tinyurlDiv}>
                {'Drag and Drop File: '}
                <input ref={this.dataInputRef} onChange={(e: any) => this.updateDataEntry(e)} value={inputData} />
              </div>
              <div class={style.btnSaveDiv}>
                <button class={style.btnSave} onClick={this.handleDataSaveR}>
                  {'Go '}
                </button>
              </div>
            </div>
          </div>
        )}
        {showChatBox !== undefined && showChatBox && <ChatBox onCloseXClick={() => this.setState({ showChatBox: false })} />}
      </Fragment>
    );
  }
  /* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
  public componentDidUpdate() {
    if (this.dataInputRef.current) {
      this.dataInputRef.current.focus();
    }
  }
  public componentDidMount() {
    this.setState({ showAllExisting: false });
    if (this.origUrlInputRef.current) {
      this.origUrlInputRef.current.focus();
    }
  }
  private handleBtnReset = () => {
    this.setState({
      error: undefined,
      result: undefined,
      original: '',
      shortened: '',
      description: '',
    });
  };
  private handleBtnRecopyToClipboard = (res: ReturnedShortURL) => {
    const { host, shortUrl } = res;
    const url = `${host}/${shortUrl}`;
    copyToClipboard(url);
  };
  private handleDataUploadRequest = () => {
    this.setState({ showDataInput: true });
  };
  private updateDataEntry = async (e: Event) => {
    const data = (e.srcElement as HTMLInputElement).value;
    const allExisting = await get<AllExisting[]>(`/verifiedUpload?data=${data}`).then(function (res) {
      if (res[0].original !== 'nope') {
        res.forEach((r: AllExisting) => {
          const arr = r.date.split('T');
          r.date = arr[0].replace(/-/g, '').slice(2);
          const src = r.original.includes('youtube.com')
            ? ' (YouTube)'
            : r.original.includes('brandnewtube.com')
            ? ' (BrandNewTube)'
            : r.original.includes('rumble.com')
            ? ' (Rumble)'
            : r.original.includes('bitchute.com')
            ? ' (Bitchute)'
            : r.original.includes('twitter.com')
            ? ' (Twitter)'
            : r.original.includes('ugetube.com')
            ? ' (UGETube)'
            : r.original.includes('thegatewaypundit.com')
            ? ' (TGP)'
            : r.original.includes('rumormillnews.com')
            ? ' (RumorMillNews)'
            : r.original.includes('theepochtimes.com')
            ? ' (EpochTimes)'
            : r.original.includes('forbes.com')
            ? ' (Forbes)'
            : r.original.includes('brighteon.com')
            ? ' (Brighteon)'
            : r.original.includes('breitbart.com')
            ? ' (Breitbart)'
            : r.original.includes('lifesitenews.com')
            ? ' (LifeSiteNews)'
            : r.original.includes('banthis.tv')
            ? ' (AlexJones)'
            : r.original.includes('thepostmillennial.com')
            ? ' (PostMillennial)'
            : r.original.includes('newz.icu')
            ? ' (NewzIcu)'
            : r.original.includes('amazon.')
            ? ' (Amazon)'
            : r.original.includes('facebook.com')
            ? ' (Facebook)'
            : r.original.includes('telegraph.co.uk')
            ? ' (UKTelegraph)'
            : r.original.includes('naturalnews.com')
            ? ' (NaturalNews)'
            : r.original.includes('theguardian.com')
            ? ' (UKGuardian)'
            : '';
          const url = r.original.replace('http://', '').replace('https://', '').replace('brandnewtube.com/watch/', '').replace(/\-/g, ' ');
          if (r.description === '') r.description = url;
          else r.description = r.description + src;
        });
      }
      return res;
    });
    if (allExisting[0].original !== 'nope') {
      this.setState({ showAllExisting: !this.state.showAllExisting, allExisting, showDataInput: false });
    }
  };
  private emailRecentUsageToAdmin = async () => {
    const allExisting = await get<AllExisting[]>('/emailRecentUsageToAdmin').then(function (res) {
      res.forEach((r: AllExisting) => {
        const arr = r.date.split('T');
        r.date = arr[0].replace(/-/g, '').slice(2);
        const src = r.original.includes('youtube.com')
          ? ' (YouTube)'
          : r.original.includes('brandnewtube.com')
          ? ' (BrandNewTube)'
          : r.original.includes('rumble.com')
          ? ' (Rumble)'
          : r.original.includes('bitchute.com')
          ? ' (Bitchute)'
          : r.original.includes('twitter.com')
          ? ' (Twitter)'
          : '';
        const url = r.original.replace('http://', '').replace('https://', '').replace('brandnewtube.com/watch/', '').replace(/\-/g, ' ');
        if (r.description === '') r.description = url;
        else r.description = r.description + src;
      });
      return res;
    });
    this.setState({ showAllExisting: true, allExisting });
  };
  private handleDataSaveR = () => {
    this.setState({ showDataInput: undefined });
  };

  private updateTinyUrl = (e: Event) => {
    /*  dashboard-conc : Compo : KPI : Classification : Compo : CreateLabels.tsx - Line 195 (handleChangeLabelDimensions)  */
    /*  dashboard-conc : Compo : KPI : Clustering : ClusterAnalysis.tsx - Line 457 (handleChangeKValue)  */
    const url = (e.srcElement as HTMLInputElement).value;
    let currRowToEdit: AllExisting =
      this.state.rowToEdit !== undefined ? { ...this.state.rowToEdit } : { id: 0, date: 'date', short: '', original: '', description: '', acc_cnt: 0 };
    currRowToEdit = { ...currRowToEdit, short: url };
    this.setState({ rowToEdit: currRowToEdit });
  };
  private updateDescription = (e: Event) => {
    const desc = (e.srcElement as HTMLInputElement).value;
    //const rowToEdit = this.state.rowToEdit;
    let currRowToEdit: AllExisting =
      this.state.rowToEdit !== undefined ? { ...this.state.rowToEdit } : { id: 0, date: 'date', short: '', original: '', description: '', acc_cnt: 0 };
    currRowToEdit = { ...currRowToEdit, description: desc };
    this.setState({ rowToEdit: currRowToEdit });
  };
  private handleEditSave = () => {
    const rowToEdit = this.state.rowToEdit;
    post<AllExisting>('/updateShortenedURL', rowToEdit).then((x) => {
      this.setState({ rowToEdit: undefined, showAllExisting: !this.state.showAllExisting });
      this.emailRecentUsageToAdmin();
    });
  };
  private handleCopyShort = (row: AllExisting) => {
    const url = `https://url.hcqwerx.com/${row.short}`;
    copyToClipboard(url);
  };
  private handleCopyOriginal = (row: AllExisting) => {
    copyToClipboard(row.original);
  };
  private handleDelete = (row: AllExisting) => {
    post<AllExisting>('/deleteShortenedURL', row).then((x) => {
      this.setState({ showAllExisting: !this.state.showAllExisting });
      this.emailRecentUsageToAdmin();
    });
  };
  private handleEdit = (row: AllExisting) => {
    this.setState({ rowToEdit: row });
  };
  private checkFullyQualified = (e: KeyboardEvent) => {
    if (e.key !== 'Enter') {
      const original = (e.srcElement as HTMLInputElement).value;
      const isValid = /^https?:\/\//g.test(original);
      this.setState({
        original,
        error: isValid ? undefined : 'Please enter a full original url. Must start with http(s)://',
      });
    } else if (this.state.error === undefined) {
      this.handleSubmit();
    }
  };
  private handleDescriptionInput = (e: Event) => {
    const description = (e.srcElement as HTMLInputElement).value;
    this.setState({ description });
  };
  private checkShortUrlInput = async (e: KeyboardEvent) => {
    if (e.key !== 'Enter') {
      const shortened = (e.srcElement as HTMLInputElement).value;
      this.setState({ shortened }, () => {
        this.checkForAllowableShortName(shortened);
      });
    } else if (e.key === 'Enter' && this.state.error === undefined) {
      this.handleSubmit();
    }
  };
  private checkForAllowableShortName = debounce(async (value: string) => {
    const isValid = await fetch('/chkForAllowables', {
      method: 'POST',
      body: JSON.stringify({
        short: value,
      }),
      mode: 'same-origin',
      credentials: 'include',
      headers: {
        'Content-type': 'application/json',
      },
    }).then(function (res) {
      return res.json();
    });
    this.setState({
      error: isValid ? undefined : 'Please enter a unique custom short URL',
    });
  }, 600);
  private handleSubmit = () => {
    fetch('/', {
      method: 'POST',
      body: JSON.stringify({
        short: this.state.shortened,
        original: this.state.original,
        description: this.state.description,
      }),
      mode: 'same-origin',
      credentials: 'include',
      headers: {
        'Content-type': 'application/json',
      },
    })
      .then(function (res) {
        return res.json();
      })
      .then((msg: string | AppState['result']) => {
        if (typeof msg === 'string') {
          this.setState({ error: msg });
          return;
        } else if (msg?.shortUrl) {
          const { host, shortUrl } = msg;
          const url = `${host}/${shortUrl}`;
          this.setState({ result: msg });
          copyToClipboard(url);
        }
      });
  };
  private handleHeaderClick = (column: keyof AllExisting) => {
    console.log('handleHeaderClick');
    const { sortColumn, sortDirection } = this.state;
    let newSortDirection: 'asc' | 'desc' = 'asc';
    if (sortColumn === column) {
      newSortDirection = sortDirection === 'asc' ? 'desc' : 'asc';
    }
    this.setState({ sortColumn: column, sortDirection: newSortDirection });
  };
}
