import { h, FunctionalComponent } from 'preact';
import * as style from './tablerow.scss';
import { AllExisting } from '../App';

interface TableRowProps {
  row: AllExisting;
  onDel(row: AllExisting): void;
  onEdit(row: AllExisting): void;
  onCopyShort(row: AllExisting): void;
  onCopyOriginal(row: AllExisting): void;
}

export const TableRow: FunctionalComponent<TableRowProps> = ({ row, onDel, onEdit, onCopyShort, onCopyOriginal }) => {
  //Next 2 lines no longer work b/c date is being changed in App.tsx get handler
  const dateUpdated = row.date !== undefined ? row?.date?.toLocaleString() : undefined;
  const displayDate = dateUpdated !== undefined ? dateUpdated.split(' ')[0].replace(',', '') : '';
  return (
    <div class={style.tableRowDiv}>
      <div class={style.id}>{row.id + ''}</div>
      <div class={style.date}>{displayDate}</div>
      <div class={style.tiny}>
        <a class={style.alink} href={row.original} target={'blank'}>
          {row.short}
        </a>
      </div>
      <div class={style.desc} title={row.original} onClick={() => onEdit(row)}>
        {row.description}
      </div>
      <div class={style.cnt}>{row.acc_cnt === 0 ? '' : row.acc_cnt.toString()}</div>
      <div class={style.del}>
        <button class={style.btns} onClick={() => onDel(row)}>
          del
        </button>
      </div>
      <div class={style.cpO}>
        <button title='Copy Original URL (to clipboard)' class={style.btns} onClick={() => onCopyOriginal(row)}>
          cpO
        </button>
      </div>
      <div class={style.cpS}>
        <button title='Copy hcqWerx ShortURL (to clipboard)' class={style.btns} onClick={() => onCopyShort(row)}>
          cpS
        </button>
      </div>
    </div>
  );
};
